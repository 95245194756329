import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ActiveEye, NoActiveEye } from "../icons/index";
import { useLogin } from "../hooks/useLogin";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "../redux/apiCalls/authApiSlice";
import { setCredentials } from "../redux/slices/authSlice";
import { useEffect } from "react";

export default function LoginPage() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { login: firstLog, isLoading, error } = useLogin();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [login, { isLoading: loading, error: Err }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/dashboard");
    }
  }, [navigate, userInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await login({ username: email, password }).unwrap();
      dispatch(setCredentials({ ...res }));

      await firstLog(email, password, true);
      navigate("/dashboard");
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(Err?.data?.message);

  return (
    <div className="relative h-screen px-5 py- bg-background bg-cover bg-center flex flex-col justify-center items-center gap-10">
      <div className="bg-whit">
        <img
          className="w-72 mx-auto mb-0 block "
          src="./assets/Nabhorelan-logo_1.png"
          alt="logo"
        />
      </div>

      <div className=" w-full sm:w-[600px] md:w-[700px] lg:w-[800px] rounded-3xl p-5  md:px-32 md:py-10 bg-white/20 shadow-black ">
        <div className="mb-14">
          <h2 className="font-bold text-4xl text-logoGreen mb-1.5 text-center">
            Espace Admin
          </h2>
          <p className="text-lg text-black text-center">
            Connecter-vous a votre espace admin
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            required
            type="text"
            placeholder="Phone Number"
            className="mb-3 outline-none border focus:border-logoGreen rounded-lg w-full px-[15px] py-4 bg-white/70"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <div className={"relative flex flex-wrap w-full "}>
            <input
              className={
                "outline-none border focus:border-logoGreen rounded-lg w-full px-[15px] py-4 bg-white/70"
              }
              placeholder={"Mot de passe"}
              type={!showPassword ? "password" : "text"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <span className="z-10 w-[120px] flex justify-around h-full  text-center text-logoGreen absolute bg-transparent rounded text-base items-center right-2 ">
              {showPassword ? (
                <ActiveEye
                  width="25px"
                  height="25px"
                  className="cursor-pointer"
                  listener={() => setShowPassword(!showPassword)}
                />
              ) : (
                <NoActiveEye
                  width="25px"
                  height="25px"
                  className="cursor-pointer"
                  listener={() => setShowPassword(!showPassword)}
                />
              )}
              <span className="border border-l-black h-11 opacity-40"></span>
              <span
                className="flex-[0.8] cursor-pointer"
                onClick={() => navigate("/forgot_password")}
              >
                Oublié ?
              </span>
            </span>
          </div>

          {loading && (
            <span className="block mx-auto mt-6 w-3 h-3 bg-logoGreen rounded-full animate-bounce" />
          )}
          {Err && (
            <span className="block mt-6 tracking-widest text-lg text-center text-red-500">
              {Err?.data?.message}
            </span>
          )}

          <button className="mt-8 mb-4 text-white rounded-lg w-full h-[50px] transition-colors duration-300 ease-out bg-logoGreen hover:bg-logoGreen/80">
            Connexion
          </button>
        </form>
      </div>
    </div>
  );
}
