import {
  AdminIcon,
  OptionIcon,
  CarIcon,
  CityIcon,
  CommandesIcon,
  CouponsIcon,
  DashboardIcon,
  LogoutIcon,
  MessageIcon,
  OffresIcon,
  PaymentsIcon,
  UserIcon,
} from "./icons";

export const sidemenuLinks = [
  {
    name: "Mon tableau de bord",
    icon: DashboardIcon,
    path: "/dashboard",
  },
  {
    name: "Utilisateur",
    icon: UserIcon,
    path: "/users",
    protected: true,
  },
  {
    name: "Messages",
    icon: MessageIcon,
    path: "/messages",
  },
  {
    name: "Reservations",
    icon: CommandesIcon,
    path: "/commands",
  },
  {
    name: "offres",
    icon: OffresIcon,
    path: "/offers",
  },
  {
    name: "Coupons",
    icon: CouponsIcon,
    path: "/coupons",
    protected: true
  },
  {
    name: "Paiememts",
    icon: PaymentsIcon,
    path: "/payments",
  },
  {
    name: "Véhicules",
    icon: CarIcon,
    path: "/vehicules",
    protected: true
  },
  {
    name: "Villes",
    icon: CityIcon,
    path: "/cities",
    protected: true
  },
  {
    name: "Administrateurs",
    icon: AdminIcon,
    path: "/admins",
    protected: true
  },
  {
    name: "Options",
    icon: OptionIcon,
    path: "/options",
    protected: true
  },
  {
    name: "Mode de Payement",
    icon: OptionIcon,
    path: "/modepayements",
    protected: true
  },
  {
    name: "Newsletter",
    icon: OptionIcon,
    path: "/newsletter",
  },
  {
    name: "Temoignage",
    icon: OptionIcon,
    path: "/temoignage",
  },
];
