export const BrandIcon = ({ width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 344 64"
      style={{ enableBackground: "new 0 0 344 64" }}
      space="preserve"
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <g>
          <circle fill="#00A184" cx="32" cy="32" r="30" />
          <path
            fill="white"
            d="M42.2,9.7l-2.8,4.7c6.9,2.9,11.7,9.7,11.7,17.6c0,5.2-2,9.8-5.4,13.3c-0.8,0.8-2.1,0.6-2.7-0.3l-3.9-6.5
          c-0.4-0.7-0.4-1.6,0-2.3c0.8-1.4,1.2-3.1,1.1-4.9c-0.4-4.2-4-7.4-8.1-7.4c-0.1,0-0.3,0-0.4,0c-0.8,0-1.6-0.4-2-1.1l-4-6.6
          c-2.4-4-7.9-4.7-11.2-1.4C10.1,19.2,7.5,25.3,7.5,32c0,9.9,5.9,18.4,14.3,22.3l2.8-4.7c-6.9-2.9-11.7-9.7-11.7-17.6
          c0-5.2,2-9.8,5.4-13.3c0.8-0.8,2.1-0.6,2.7,0.3l3.9,6.5c0.4,0.7,0.4,1.6,0,2.3c-0.8,1.4-1.2,3.1-1.1,4.9c0.4,4.1,4,7.4,8.1,7.4
          c0.1,0,0.3,0,0.4,0c0.8,0,1.6,0.4,2,1.1l4,6.6c2.4,4,7.9,4.7,11.1,1.4c4.4-4.4,7-10.5,7-17.2C56.5,22.1,50.7,13.6,42.2,9.7z
           M32,34.7c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7S33.5,34.7,32,34.7z"
          />
        </g>
        <g>
          <path
            fill="#21163A"
            d="M84.3,54.2c-0.6-0.2-1.2-0.5-1.8-1c-0.5-0.4-0.9-0.9-1.2-1.5L81,51.2c0-5.3,0.1-11.1,0.3-17.4
          c0.2-6.2,0.3-11.3,0.4-15.2c0-1.2,0-2.7,0.1-4.6c0-1,0.1-1.7,0.2-2.2c0.1-0.4,0.3-0.7,0.5-0.8c0.2-0.1,0.5-0.1,0.8-0.1H84
          c1,0,1.9,0.4,2.6,1.2c0.8,0.9,1.7,2.8,2.6,5.9c1,3.1,2.3,7.8,4,14.3c1.1,4.1,2.1,7.5,3,10.2c0.9,2.7,1.5,4.1,1.7,4.2
          c0.4-0.1,0.7-1.5,0.9-4.3c0.2-2.8,0.3-5.3,0.4-7.7c0-2.4,0-3.7,0-4c0-4.1-0.1-8.4-0.3-13c-0.1-1.9-0.1-3.3-0.1-4.4
          c0-1.7,0.1-2.6,0.4-2.9c0.3-0.3,0.6-0.4,1-0.4l0.4,0c1.3,0.1,2.3,0.6,2.9,1.6c0.6,0.9,1,2.4,1.1,4.3c0.4,7.2,0.6,13.4,0.6,18.4
          c0,7.2-0.5,12.3-1.4,15.3c-0.8,2.5-2,3.7-3.5,3.7H100c-2.4,0-4.5-1.4-6.3-4.1c-1.8-2.7-3.5-7.1-5.2-13.3l-1.3-4.9l-0.1,1.5
          c-0.1,1.1-0.1,2.8-0.2,5.2c-0.1,2.4-0.2,4.7-0.3,7.1c-0.1,2.4-0.1,4.1-0.2,5.2c0,2-0.1,3.2-0.4,3.7c-0.2,0.4-0.6,0.7-1,0.7
          C84.9,54.4,84.6,54.3,84.3,54.2z"
          />
          <path
            fill="#21163A"
            d="M132.8,53.6c-0.5-0.2-1-0.6-1.5-1.2c-0.6-0.6-1-1.3-1.2-2.2l-0.1-0.3l-0.2,0.1c-0.2,0.1-0.3,0.3-0.5,0.5
          c-2.1,2-4.1,2.9-6.1,2.9c-1.7,0-3.3-0.7-4.8-2.2c-1.2-1.2-2.2-2.5-2.7-3.9c-0.6-1.4-0.9-2.9-0.9-4.3c0-3.4,1-6.3,3-8.7
          c1.8-2.2,4-3.3,6.6-3.5c1.8,0,3.7,0.7,5.5,2.2c0.4,0.3,0.6,0.5,0.7,0.5c0.1,0,0.1-0.2,0.1-0.5c0-0.7,0.1-1.1,0.4-1.4
          s0.6-0.4,1-0.4l0.3,0c1,0.2,1.9,1,2.7,2.5l0.4,0.8l-0.1,3.7c-0.1,1.9-0.2,3.7-0.2,5.4l-0.1,3.6c0,3-0.1,4.8-0.3,5.3
          c-0.2,0.6-0.5,0.9-1,0.9C133.3,53.7,133,53.7,132.8,53.6z M122,46.9c1-0.4,2-1.2,3.2-2.3c1.2-1.1,2.2-2.3,2.9-3.4
          c0.7-1.1,1.1-1.9,1.1-2.4c0-0.1,0-0.2,0-0.3c-0.6-1.1-1.4-1.7-2.4-1.7c-0.2,0-0.4,0-0.7,0.1c-1.3,0.3-2.6,1.1-3.7,2.5
          c-1.5,1.7-2.3,3.8-2.4,6.2c0,0.8,0.1,1.3,0.3,1.4c0.2,0.1,0.4,0.2,0.5,0.2C121.1,47.2,121.5,47.1,122,46.9z"
          />
          <path
            fill="#21163A"
            d="M147.8,53.5c-0.5-0.3-1-0.7-1.4-1.3c-0.4-0.6-0.8-1.3-1-2c-0.1-0.5-0.2-2.7-0.3-6.6
          c-0.1-3.9-0.2-8.3-0.2-13.3c-0.1-5.2-0.1-10.3-0.1-15.3c0-2.5,0-4,0.1-4.3c0.2-0.6,0.6-1,1.2-1l0.2,0c0.6,0.1,1.2,0.5,1.8,1.2
          c0.6,0.7,1,1.5,1.2,2.6c0,0.3,0.1,1.3,0.2,3.1c0.1,1.8,0.1,4,0.2,6.5c0.1,2.5,0.1,4.6,0.2,6.4c0.1,1.8,0.1,2.7,0.1,2.9
          c0,0.2,0.1,0.4,0.1,0.5l1-0.8c2.1-1.6,4.1-2.4,6.1-2.4c3,0,5.3,1.7,7,5c1,2.1,1.5,4.2,1.5,6.2c0,3.1-0.8,5.8-2.3,8
          c-0.9,1.3-1.9,2.4-3.1,3.1c-1.2,0.7-2.4,1.1-3.8,1.1c-2,0-4-1.2-5.8-3.5l-0.3-0.3l-0.1,1.6c0,2-0.5,2.9-1.5,2.9
          C148.5,53.8,148.2,53.7,147.8,53.5z M156.2,45.8c3-1.6,4.5-4.2,4.5-7.7c-0.1-1.4-0.8-2-2-2c-1.1,0-2.4,0.5-3.8,1.4
          c-1.4,0.9-2.6,2.2-3.7,3.8c-0.2,0.4-0.4,0.6-0.4,0.8c0,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.5,0.7,1,1.4c0.9,1.2,1.6,1.9,1.9,2.1
          c0.3,0.1,0.6,0.2,0.8,0.2C155.1,46.2,155.6,46.1,156.2,45.8z"
          />
          <path
            fill="#21163A"
            d="M177.6,53.9c-0.6-0.3-1.2-0.9-1.8-1.6c-0.5-0.8-0.9-1.6-1-2.4c-0.2-1.7-0.4-8.4-0.5-20c0-3.6-0.1-6.9-0.1-9.8
          c0-5.6,0-8.7,0.1-9.4c0.2-0.7,0.4-1,0.7-1h0.5c0.6,0,1.1,0.2,1.7,0.7c0.6,0.5,1.1,1.2,1.4,2.1c0.1,0.3,0.2,0.6,0.3,0.9
          c0.2,1,0.2,4.1,0.2,9.4c0,5.1,0,8.2,0.1,9.3l0.1,1.1l0.7-0.9c1.7-2,3.5-3,5.5-3c2.2,0,4.2,1.1,6,3.2c1.9,2.4,3.1,5.6,3.5,9.6
          c0.2,2,0.3,3.6,0.3,4.9c0,1,0,2-0.1,3c-0.2,2.1-0.6,3.3-1.3,3.7c-0.3,0.1-0.6,0.2-0.8,0.2c-0.5,0-0.9-0.2-1.3-0.5
          c-0.6-0.5-1.2-1.3-1.5-2.4c-0.5-1.2-0.7-2.7-0.7-4.3c0-0.5,0-1,0.1-1.6c0.1-1.3,0.2-2.4,0.2-3.5c0-1.3-0.1-2.3-0.3-3.2
          c-0.3-1.6-0.9-2.4-1.7-2.4c-1.8,0.2-3.4,1.7-4.8,4.7c-1.4,3-2.4,6.7-2.8,11.2c-0.1,1.5-0.6,2.3-1.5,2.3
          C178.4,54.2,178.1,54.1,177.6,53.9z"
          />
          <path
            fill="#21163A"
            d="M210.2,53.1c-2.3-0.9-4-2.4-5.3-4.7c-1.2-2.3-1.8-4.8-1.8-7.7c0-1.8,0.3-3.6,0.9-5.4c0.6-1.8,1.5-3.4,2.7-4.8
          c0.7-0.9,1.7-1.3,3-1.3c1.3,0,2.9,0.5,4.9,1.4c1.7,0.9,3.1,2.1,4,3.7c1.2,2.2,1.7,4.6,1.7,7.1c0,0.8-0.1,1.6-0.2,2.4
          c-0.4,3.1-1.5,5.6-3.1,7.5c-0.9,0.9-1.7,1.5-2.5,1.8c-0.8,0.2-1.5,0.4-2,0.4C211.7,53.5,211,53.4,210.2,53.1z M211.6,46.4
          c1-0.6,1.9-1.8,2.7-3.3c0.7-1.6,1.1-3.2,1.1-4.9v-0.3c0-0.3,0-0.6-0.1-0.8c-0.1-0.2-0.3-0.4-0.7-0.6c-0.4-0.2-1-0.4-1.8-0.6
          l-1.7-0.5l-0.3,0.4c-0.9,1.1-1.6,2.3-2,3.5c-0.5,1.4-0.7,2.7-0.7,3.8c0,1.4,0.3,2.5,0.9,3.2c0.3,0.4,0.7,0.6,1.2,0.6
          C210.5,46.8,211,46.7,211.6,46.4z"
          />
          <path
            fill="#21163A"
            d="M232.3,54.1c-0.7-0.3-1.3-0.8-1.9-1.6c-0.6-0.8-0.9-1.6-1.1-2.5c0-0.4-0.1-1.6-0.1-3.6c0-2-0.1-3.9-0.1-5.8
          c0-1.9,0-3.4,0-4.5c0-3.9,0-6,0.1-6.2c0.3-0.6,0.7-0.9,1.2-0.9c0.3,0,0.6,0.1,1,0.3c1,0.6,1.9,1.6,2.4,3c0,0.1,0,0.1,0.1,0.1
          c0.1,0,0.3-0.2,0.8-0.6c1.4-1.2,2.7-1.8,3.9-1.8c1.2,0,2.5,0.6,3.6,1.8c1,1.1,1.7,2,1.9,2.8c0.2,0.8,0.4,1.4,0.4,1.9
          c0,0.4-0.1,0.8-0.2,1.1c-0.2,0.5-0.6,0.7-1.1,0.7c-0.5,0-1.2-0.4-2.1-1.1l-0.7-0.6c-3.6,2.3-5.5,7-5.8,14.3c0,1.7-0.2,2.7-0.4,3.1
          c-0.3,0.4-0.6,0.5-0.9,0.5C233,54.3,232.7,54.3,232.3,54.1z"
          />
          <path
            fill="#21163A"
            d="M260.2,54c-1.7-0.4-3.2-1.1-4.4-2.1c-1.8-1.5-3-3.5-3.7-5.8c-0.3-1.2-0.5-2.5-0.5-3.7c0-2,0.4-4,1.3-6
          c1.1-2.5,3-4.5,5.6-5.9c1.4-0.7,2.6-1,3.8-1c0.3,0,0.6,0,0.8,0.1c1.5,0.2,2.7,0.7,3.8,1.6c1.8,1.6,2.7,3.5,2.7,5.8l0,0.9
          c-0.2,2-1.4,3.8-3.6,5.3c-1.5,1-2.8,1.5-3.9,1.6c-1,0.1-1.7,0.2-2.1,0.2c-1.3,0-2.3-0.3-3.1-0.8l-0.4-0.3
          c-0.1,0.6-0.1,1.1-0.1,1.4c0,1.7,0.8,2.5,2.4,2.5c1.3,0,3-0.5,5.2-1.6c1.5-0.8,2.4-1.1,2.7-1.1h0.5c0.6,0,1.2,0.3,1.8,1
          c0.9,1,1.3,1.9,1.3,2.8c0,0.7-0.3,1.4-0.9,2c-2.5,2.4-5,3.6-7.4,3.6C261.4,54.2,260.8,54.1,260.2,54z M259.9,39.4
          c1-0.3,2-0.7,2.8-1.4c0.9-0.6,1.5-1.3,1.9-2l0.1-0.1c0.2-0.3,0.3-0.6,0.3-0.7c0-0.1-0.1-0.1-0.4-0.1c-1,0-2.1,0.3-3.2,1
          c-1.1,0.7-2.1,1.6-2.9,2.7c-0.3,0.4-0.4,0.6-0.4,0.7c0,0.1,0.1,0.1,0.2,0.1C258.6,39.8,259.1,39.7,259.9,39.4z"
          />
          <path
            fill="#21163A"
            d="M282.2,53.6c-1.1-0.7-1.8-1.8-2.2-3.3c-0.2-0.9-0.3-3.2-0.5-7c-0.1-3.8-0.2-7.4-0.2-10.7
          c0-3.3-0.1-5.8-0.1-7.6c0-8.2,0.1-12.8,0.4-14c0.3-0.8,0.7-1.3,1.3-1.3c0.2,0,0.5,0.1,0.8,0.2c0.9,0.5,1.6,1.5,2.1,3.1
          c0.2,1,0.4,3.4,0.5,7.1c0.2,3.7,0.3,7.9,0.4,12.6c0.1,4.6,0.2,8.8,0.2,12.5s-0.1,6.1-0.2,7c-0.2,1-0.7,1.5-1.4,1.5
          C282.9,53.9,282.6,53.8,282.2,53.6z"
          />
          <path
            fill="#21163A"
            d="M312,53.6c-0.5-0.2-1-0.6-1.5-1.2c-0.6-0.6-1-1.3-1.2-2.2l-0.1-0.3l-0.2,0.1c-0.2,0.1-0.3,0.3-0.5,0.5
          c-2.1,2-4.1,2.9-6.1,2.9c-1.7,0-3.3-0.7-4.8-2.2c-1.2-1.2-2.2-2.5-2.7-3.9c-0.6-1.4-0.9-2.9-0.9-4.3c0-3.4,1-6.3,3-8.7
          c1.8-2.2,4-3.3,6.6-3.5c1.8,0,3.7,0.7,5.5,2.2c0.4,0.3,0.6,0.5,0.7,0.5c0.1,0,0.1-0.2,0.1-0.5c0-0.7,0.1-1.1,0.4-1.4
          s0.6-0.4,1-0.4l0.3,0c1,0.2,1.9,1,2.7,2.5l0.4,0.8l-0.1,3.7c-0.1,1.9-0.2,3.7-0.2,5.4l-0.1,3.6c0,3-0.1,4.8-0.3,5.3
          c-0.2,0.6-0.5,0.9-1,0.9C312.5,53.7,312.2,53.7,312,53.6z M301.2,46.9c1-0.4,2-1.2,3.2-2.3c1.2-1.1,2.2-2.3,2.9-3.4
          c0.7-1.1,1.1-1.9,1.1-2.4c0-0.1,0-0.2,0-0.3c-0.6-1.1-1.4-1.7-2.4-1.7c-0.2,0-0.4,0-0.7,0.1c-1.3,0.3-2.6,1.1-3.7,2.5
          c-1.5,1.7-2.3,3.8-2.4,6.2c0,0.8,0.1,1.3,0.3,1.4c0.2,0.1,0.4,0.2,0.5,0.2C300.3,47.2,300.7,47.1,301.2,46.9z"
          />
          <path
            fill="#21163A"
            d="M339.1,53.8c-0.9-0.5-1.6-1.3-2.2-2.4l-0.5-0.9c-0.1-7-0.2-10.8-0.3-11.3c-0.1-0.6-0.2-1.1-0.3-1.4
          c-0.1-0.3-0.2-0.4-0.3-0.4c-0.1,0-0.3,0.1-0.5,0.2c-1.6,0.8-2.8,2.3-3.7,4.4c-0.9,2.2-1.4,4.8-1.5,7.8c0,1.8-0.2,2.9-0.5,3.3
          c-0.3,0.4-0.6,0.6-0.9,0.6c-0.3,0-0.8-0.1-1.3-0.4c-0.9-0.4-1.6-1-2-1.7c-0.4-0.8-0.8-2.1-1-4.1c-0.2-2-0.4-4.9-0.5-8.9
          c-0.1-1.5-0.1-2.8-0.1-3.8c0-1,0-1.7-0.1-2.1c0-0.4,0-0.7,0-1c0-0.6,0-1,0.1-1.1c0-0.1,0.1-0.2,0.1-0.3c0.3-0.4,0.6-0.5,1.1-0.5
          c0.2,0,0.3,0,0.5,0.1c0.6,0.2,1.3,0.5,1.9,1.1c0.6,0.6,1,1.3,1.2,2.1c0.1,0.3,0.1,0.5,0.2,0.5h0.1c0.1,0,0.2-0.1,0.4-0.3
          c1.5-1.6,3.1-2.3,4.6-2.3c0.2,0,0.4,0,0.6,0.1c2.4,0.4,4.3,2.2,5.8,5.4c1.4,2.9,2,6.5,2,10.8c0,3.4-0.2,5.5-0.5,6.2
          c-0.3,0.7-0.7,1-1.1,1C340,54.2,339.5,54.1,339.1,53.8z"
          />
        </g>
      </g>
    </svg>
  );
};

export const AdminIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M7,3h20c2.2,0,4,1.8,4,4v20c0,2.2-1.8,4-4,4H7c-2.2,0-4-1.8-4-4V7C3,4.8,4.8,3,7,3z" />
        <circle cx="17" cy="11.4" r="4.1" />
        <path d="M8.1,28.6c0-4.9,4-8.9,8.9-8.9s8.9,4,8.9,8.9" />
        <line x1="8.1" y1="27.9" x2="8.1" y2="31" />
        <line x1="25.9" y1="27.9" x2="25.9" y2="31" />
      </g>
    </svg>
  );
};

export const ArchiveIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M5.5,12h22.9v15.2c0,2.1-1.7,3.8-3.8,3.8H9.4c-2.1,0-3.8-1.7-3.8-3.8V12z" />
        <path
          d="M6.8,3h20.4C29.3,3,31,4.7,31,6.8v3.9c0,0.7-0.6,1.2-1.2,1.2H4.2C3.6,12,3,11.4,3,10.7V6.8C3,4.7,4.7,3,6.8,3z
		"
        />
        <line x1="12.9" y1="17.8" x2="21.1" y2="17.8" />
      </g>
    </svg>
  );
};

export const CarIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M11.4,28.4c0,1.4-1.2,2.6-2.6,2.6s-2.6-1.2-2.6-2.6" />
        <path d="M6.8,25.8C4.7,25.8,3,24.1,3,22s1.7-3.8,3.8-3.8" />
        <path d="M11.7,7.3C12.2,4.9,14.4,3,17,3" />
        <path d="M17,7.3h-5.2c-1.5,0-2.9,0.9-3.4,2.3l-3.6,9.1" />
        <line x1="6.8" y1="18.2" x2="17" y2="18.2" />
        <line x1="6.8" y1="25.8" x2="17" y2="25.8" />
        <line x1="8.7" y1="20.6" x2="8.7" y2="18.2" />
        <line x1="6.1" y1="28.4" x2="6.1" y2="25.7" />
        <line x1="11.4" y1="28.4" x2="11.4" y2="25.8" />
        <line x1="3.4" y1="14.5" x2="6.4" y2="14.5" />
        <path d="M22.6,28.4c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6" />
        <path d="M27.2,25.8c2.1,0,3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8" />
        <path d="M22.3,7.3C21.8,4.9,19.6,3,17,3" />
        <path d="M17,7.3h5.2c1.5,0,2.9,0.9,3.4,2.3l3.6,9.1" />
        <line x1="27.2" y1="18.2" x2="17" y2="18.2" />
        <line x1="27.2" y1="25.8" x2="17" y2="25.8" />
        <line x1="25.3" y1="20.6" x2="25.3" y2="18.2" />
        <line x1="27.9" y1="28.4" x2="27.9" y2="25.7" />
        <line x1="22.6" y1="28.4" x2="22.6" y2="25.8" />
        <line x1="30.6" y1="14.5" x2="27.6" y2="14.5" />
      </g>
    </svg>
  );
};

export const CityIcon = ({ color, width, height, className, listener }) => {
  const st0 = {
    fill: "none",
    stroke: "#21163A",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
  };

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M7.3,3h8.8c2.4,0,4.3,1.9,4.3,4.3V31H7.3C4.9,31,3,29.1,3,26.7V7.3C3,4.9,4.9,3,7.3,3z" />
        <path d="M26.7,31h-6.4V9.7h6.4c2.4,0,4.3,1.9,4.3,4.3v12.8C31,29.1,29.1,31,26.7,31z" />
        <line style={st0} x1="14.6" y1="9.7" x2="14.6" y2="9.7" />
        <line style={st0} x1="14.6" y1="14.7" x2="14.6" y2="14.7" />
        <line style={st0} x1="14.6" y1="19.7" x2="14.6" y2="19.7" />
        <line style={st0} x1="14.6" y1="24.7" x2="14.6" y2="24.7" />
        <line style={st0} x1="8.7" y1="9.7" x2="8.7" y2="9.7" />
        <line style={st0} x1="8.7" y1="14.7" x2="8.7" y2="14.7" />
        <line style={st0} x1="8.7" y1="19.7" x2="8.7" y2="19.7" />
        <line style={st0} x1="8.7" y1="24.7" x2="8.7" y2="24.7" />
        <line style={st0} x1="25.7" y1="9.7" x2="25.7" y2="9.7" />
        <line style={st0} x1="25.7" y1="14.7" x2="25.7" y2="14.7" />
        <line style={st0} x1="25.7" y1="19.7" x2="25.7" y2="19.7" />
        <line style={st0} x1="25.7" y1="24.7" x2="25.7" y2="24.7" />
      </g>
    </svg>
  );
};

export const CommandesIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M12.7,15H4c-0.6,0-1-0.4-1-1V4c0-0.6,0.4-1,1-1h8.7c0.6,0,1,0.4,1,1v10C13.7,14.5,13.2,15,12.7,15z" />
        <path d="M12.7,31H4c-0.6,0-1-0.4-1-1V20c0-0.6,0.4-1,1-1h8.7c0.6,0,1,0.4,1,1v10C13.7,30.6,13.2,31,12.7,31z" />
        <line x1="17" y1="21.1" x2="31" y2="21.1" />
        <line x1="17" y1="25.7" x2="24.8" y2="25.7" />
        <line x1="17" y1="5.1" x2="31" y2="5.1" />
        <line x1="17" y1="9.7" x2="24.8" y2="9.7" />
      </g>
    </svg>
  );
};

export const CouponsIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path
          d="M27.5,6.3v21.4c0,1.8-1.5,3.3-3.3,3.3h-1.7c-0.4,0-0.8-0.3-0.9-0.8c-0.4-2.2-2.2-3.8-4.5-3.8
		c-2.3,0-4.2,1.7-4.5,3.8C12.4,30.7,12,31,11.6,31H9.8c-1.8,0-3.3-1.5-3.3-3.3V6.3C6.5,4.5,8,3,9.8,3h1.7c0.4,0,0.8,0.3,0.9,0.8
		c0.4,2.2,2.2,3.8,4.5,3.8c2.3,0,4.2-1.7,4.5-3.8C21.6,3.3,22,3,22.4,3h1.7C26,3,27.5,4.5,27.5,6.3z"
        />
        <line x1="6.5" y1="21.8" x2="10.4" y2="21.8" />
        <line x1="15" y1="21.8" x2="19" y2="21.8" />
        <line x1="23.6" y1="21.8" x2="27.5" y2="21.8" />
      </g>
    </svg>
  );
};

export const EditIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M6.8,31H3v-3.8l14.3-14.3c1-1,2.7-1,3.8,0l0,0c1,1,1,2.7,0,3.8L6.8,31z" />
        <line x1="14.6" y1="15.6" x2="18.4" y2="19.4" />
        <path d="M26.8,3l-0.6,2.2c-0.2,0.6-0.7,1.1-1.3,1.3l-2.2,0.6" />
        <path d="M22.7,7.2l2.2,0.6C25.5,8,26,8.5,26.2,9.1l0.6,2.2" />
        <path d="M26.8,11.3l0.6-2.2c0.2-0.6,0.7-1.1,1.3-1.3L31,7.2" />
        <path d="M31,7.2l-2.2-0.6c-0.6-0.2-1.1-0.7-1.3-1.3L26.8,3" />
        <path d="M26.8,17.4l-0.6,2.2c-0.2,0.6-0.7,1.1-1.3,1.3l-2.2,0.6" />
        <path d="M22.7,21.6l2.2,0.6c0.6,0.2,1.1,0.7,1.3,1.3l0.6,2.2" />
        <path d="M26.8,25.7l0.6-2.2c0.2-0.6,0.7-1.1,1.3-1.3l2.2-0.6" />
        <path d="M31,21.6l-2.2-0.6c-0.6-0.2-1.1-0.7-1.3-1.3l-0.6-2.2" />
        <path d="M12.4,3l-0.6,2.2c-0.2,0.6-0.7,1.1-1.3,1.3L8.3,7.2" />
        <path d="M8.3,7.2l2.2,0.6c0.6,0.2,1.1,0.7,1.3,1.3l0.6,2.2" />
        <path d="M12.4,11.3l0.6-2.2c0.2-0.6,0.7-1.1,1.3-1.3l2.2-0.6" />
        <path d="M16.6,7.2l-2.2-0.6c-0.6-0.2-1.1-0.7-1.3-1.3L12.4,3" />
      </g>
    </svg>
  );
};

export const DropIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <polyline points="26,12.5 17,21.5 8,12.5 " />
    </svg>
  );
};

export const DashboardIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M13.1,11.3H4c-0.6,0-1-0.4-1-1V4c0-0.6,0.4-1,1-1h9.1c0.6,0,1,0.4,1,1v6.3C14.1,10.8,13.7,11.3,13.1,11.3z" />
        <path d="M30,18.9H19.1c-0.6,0-1-0.4-1-1V4c0-0.6,0.4-1,1-1H30c0.6,0,1,0.4,1,1v13.9C31,18.5,30.6,18.9,30,18.9z" />
        <path d="M30,31H19.1c-0.6,0-1-0.4-1-1v-6.3c0-0.6,0.4-1,1-1H30c0.6,0,1,0.4,1,1V30C31,30.6,30.6,31,30,31z" />
        <path d="M13.1,31H4c-0.6,0-1-0.4-1-1V16.1c0-0.6,0.4-1,1-1h9.1c0.6,0,1,0.4,1,1V30C14.1,30.6,13.7,31,13.1,31z" />
      </g>
    </svg>
  );
};

export const EmojiIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill="none"
      width={width}
      height={height}
      className={className}
      onClick={listener}
    >
      <g>
        <path d="M11.1,22.9c3.2,3.2,8.5,3.2,11.8,0" />
        <circle cx="17" cy="17" r="14" />
        <path d="M12.9,11.3h-0.4c-0.7,0-1.3,0.6-1.3,1.3v2.1h3.1v-2.1C14.2,11.9,13.6,11.3,12.9,11.3z" />
        <path d="M21.1,11.3h0.4c0.7,0,1.3,0.6,1.3,1.3v2.1h-3.1v-2.1C19.8,11.9,20.4,11.3,21.1,11.3z" />
      </g>
    </svg>
  );
};

export const LogoutIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={listener}
    >
      <g>
        <path
          d="M17.4,21.5v5.8c0,2.1-1.6,3.7-3.7,3.7h-7C4.6,31,3,29.4,3,27.3V6.7C3,4.6,4.6,3,6.7,3h7c2.1,0,3.7,1.6,3.7,3.7
		v5.4 M25.8,23l4.5-4.5c0.8-0.8,0.8-2.1,0.1-2.9L25.9,11"
        />
        <line x1="10.2" y1="17" x2="31" y2="17" />
      </g>
    </svg>
  );
};

export const MessageIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path
          d="M31,4v20.2c0,0.6-0.4,1-1,1h-5.3c-1.1,0-2.1,0.4-2.8,1.2l-4.2,4.3c-0.4,0.4-1,0.4-1.4,0l-4.2-4.3
		c-0.8-0.8-1.8-1.2-2.8-1.2H4c-0.6,0-1-0.5-1-1V4c0-0.6,0.4-1,1-1h26C30.6,3,31,3.5,31,4z"
        />
        <line x1="8" y1="11.4" x2="26" y2="11.4" />
        <line x1="8" y1="16.9" x2="18.4" y2="16.9" />
      </g>
    </svg>
  );
};

export const NotificationIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      x
      <g>
        <path d="M11,26.3c0.7,2.7,3.1,4.7,6,4.7c2.9,0,5.4-2,6-4.7" />
        <path
          d="M25.3,26.1c-0.1,0-0.1,0-0.2,0l-0.4,0l0,0c-5.1,0.4-10.2,0.4-15.3,0h0l-0.4,0c-0.1,0-0.1,0-0.2,0
                c-2.9-0.5-4.8-3.1-4.5-6l0-0.2l0.9-6l0,0C5.7,7.7,10.8,3,17,3h0c6.2,0,11.3,4.7,11.8,10.9l0,0l0.9,6l0,0.2
                C30.1,23,28.2,25.6,25.3,26.1z"
        />
      </g>
    </svg>
  );
};

export const OffresIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path
          d="M14.7,10c-0.4,0-0.7-0.1-1.1-0.1C7.7,9.9,3,14.6,3,20.5S7.7,31,13.5,31s10.5-4.7,10.5-10.5
		c0-0.4,0-0.7-0.1-1.1"
        />
        <path
          d="M20.4,20.2l4.7-1.2c1.1-0.3,1.5-1.6,0.7-2.5l-8.4-8.4c-0.8-0.8-2.2-0.4-2.5,0.7l-1.2,4.7
		c-0.4,1.7-1.3,3.2-2.5,4.4l-2.1,2.1c-0.9,0.9-0.9,2.4,0,3.4l1.3,1.3c0.9,0.9,2.4,0.9,3.4,0l2.1-2.1C17.2,21.5,18.7,20.7,20.4,20.2z
		"
        />
        <line x1="13" y1="15.6" x2="16.4" y2="18.9" />
        <line x1="21.5" y1="5.1" x2="21.5" y2="3" />
        <path d="M24.1,15.1c1.4-1.4,1.4-3.8,0-5.2s-3.8-1.4-5.2,0" />
        <line x1="28.9" y1="12.5" x2="31" y2="12.5" />
        <line x1="26.3" y1="7.7" x2="27.8" y2="6.2" />
        <line x1="13.3" y1="25.2" x2="17.6" y2="29.5" />
        <line x1="15.3" y1="23.4" x2="20.4" y2="28.4" />
      </g>
    </svg>
  );
};

export const PaymentsIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path
          d="M4.8,8.9l3-0.5c3-0.5,6-0.5,9,0l0.3,0.1c3,0.5,6,0.5,9,0l2.4-0.4c1.3-0.2,2.4,0.8,2.4,2.2v12.6
		c0,1.1-0.8,2-1.8,2.2l-3,0.5c-3,0.5-6,0.5-9,0l-0.3-0.1c-3-0.5-6-0.5-9,0l-2.4,0.4C4.2,26.1,3,25.1,3,23.7l0-12.6
		C3,10,3.8,9.1,4.8,8.9z"
        />
        <ellipse cx="17" cy="17" rx="3.7" ry="3.6" />
        <ellipse cx="8.5" cy="13.4" rx="0.5" ry="0.5" />
        <ellipse cx="8.5" cy="20.6" rx="0.5" ry="0.5" />
        <ellipse cx="25.5" cy="13.4" rx="0.5" ry="0.5" />
        <ellipse cx="25.5" cy="20.6" rx="0.5" ry="0.5" />
      </g>
    </svg>
  );
};

export const SearchIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={listener}
    >
      <g>
        <circle cx="15.4" cy="15.4" r="12.4" />
        <line x1="31" y1="31" x2="24.2" y2="24.2" />
      </g>
    </svg>
  );
};

export const SendIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={listener}
    >
      <g>
        <polygon points="17.8,26.1 22.7,31 31,3 3,11.3 7.9,16.2 7.9,26.1 	" />
        <line x1="31" y1="3" x2="7.9" y2="26.1" />
      </g>
    </svg>
  );
};

export const UserIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <circle cx="17" cy="9" r="6" />
        <ellipse cx="17" cy="25" rx="10" ry="6" />
      </g>
    </svg>
  );
};

export const CheckIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <circle cx="17" cy="17" r="14" />
        <polyline points="9.9,18.9 15.7,23.4 23.6,12.8 	" />
      </g>
    </svg>
  );
};

// user section icons

export const BioIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <polygon points="28.3,14.8 18.4,24.8 18.4,27.5 21,27.5 31,17.5 	" />
        <line x1="31" y1="31" x2="17.3" y2="31" />
        <circle cx="13" cy="9" r="6" />
        <path d="M19,20.2c-1.7-0.7-3.7-1.2-6-1.2c-5.5,0-10,2.7-10,6c0,3.3,4.5,6,10,6" />
      </g>
    </svg>
  );
};

export const BlockIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <circle cx="17" cy="17" r="14" />
        <line x1="11.5" y1="11.5" x2="22.5" y2="22.5" />
        <line x1="22.5" y1="11.5" x2="11.5" y2="22.5" />
      </g>
    </svg>
  );
};

export const CardIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M4,7h26c0.6,0,1,0.4,1,1v18c0,0.6-0.4,1-1,1H4c-0.6,0-1-0.4-1-1V8C3,7.4,3.4,7,4,7z" />
        <path
          d="M11.6,18.2H7.2c-0.6,0-1-0.4-1-1v-4.4c0-0.6,0.4-1,1-1h4.4c0.6,0,1,0.4,1,1v4.4C12.6,17.8,12.1,18.2,11.6,18.2
        z"
        />
        <line x1="17.9" y1="11.8" x2="27.8" y2="11.8" />
        <line x1="17.9" y1="15.3" x2="24.4" y2="15.3" />
      </g>
    </svg>
  );
};

export const DateIcon = ({ color, width, height, className, listener }) => {
  const st0 = {
    fill: "none",
    // stroke: "#21163A",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
  };
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <line x1="9.5" y1="8" x2="9.5" y2="3" />
        <line x1="24.5" y1="8" x2="24.5" y2="3" />
        <g>
          <line style={st0} x1="10.3" y1="26.5" x2="10.3" y2="26.5" />
          <line style={st0} x1="14.8" y1="26.5" x2="14.8" y2="26.5" />
          <line style={st0} x1="19.2" y1="26.5" x2="19.2" y2="26.5" />
          <line style={st0} x1="23.7" y1="26.5" x2="23.7" y2="26.5" />
          <line style={st0} x1="10.3" y1="22.3" x2="10.3" y2="22.3" />
          <line style={st0} x1="14.8" y1="22.3" x2="14.8" y2="22.3" />
          <line style={st0} x1="19.2" y1="22.3" x2="19.2" y2="22.3" />
          <line style={st0} x1="23.7" y1="22.3" x2="23.7" y2="22.3" />
          <line style={st0} x1="10.3" y1="18.1" x2="10.3" y2="18.1" />
          <line style={st0} x1="14.8" y1="18.1" x2="14.8" y2="18.1" />
          <line style={st0} x1="19.2" y1="18.1" x2="19.2" y2="18.1" />
          <line style={st0} x1="23.7" y1="18.1" x2="23.7" y2="18.1" />
        </g>
        <path
          style={st0}
          d="M26.5,31h-19c-1.7,0-3-1.3-3-3V11c0-1.7,1.3-3,3-3h19c1.7,0,3,1.3,3,3v17C29.5,29.7,28.2,31,26.5,31z"
        />
        <line style={st0} x1="4.5" y1="13.6" x2="29.5" y2="13.6" />
      </g>
    </svg>
  );
};

export const EditIcon2 = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={listener}
    >
      <g>
        <path
          d="M31,14.3v7.5c0,1.1-0.4,2.2-1.2,3l-5,5c-0.8,0.8-1.9,1.2-3,1.2H8.3C5.4,31,3,28.6,3,25.7V8.3
		C3,5.4,5.4,3,8.3,3H20"
        />
        <path d="M20.9,31v-7.3c0-1.5,1.3-2.8,2.8-2.8H31" />
        <path d="M10.3,20.4L27,3.7c0.9-0.9,2.4-0.9,3.3,0l0,0c0.9,0.9,0.9,2.4,0,3.3L13.6,23.7" />
        <polyline points="13.6,23.7 10.3,23.7 10.3,20.4 	" />
      </g>
    </svg>
  );
};

export const GenderIcon = ({ color, width, height, className, listener }) => {
  const st0 = {
    fill: "none",
    stroke: "#21163A",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
  };
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <polyline points="31,11.6 31,3 22.4,3 	" />
        <circle cx="10.5" cy="14.8" r="7.5" />
        <circle style={st0} cx="19.2" cy="14.8" r="7.5" />
        <line x1="10.5" y1="31" x2="10.5" y2="22.3" />
        <line x1="6.2" y1="26.7" x2="14.9" y2="26.7" />
        <line x1="31" y1="3" x2="24.5" y2="9.5" />
      </g>
    </svg>
  );
};

export const BackArrowIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={listener}
    >
      <g>
        <path d="M9.9,9l-6.1,6.1c-1.1,1-1.1,2.8-0.1,3.9L9.9,25" />
        <line x1="31" y1="17" x2="3" y2="17" />
      </g>
    </svg>
  );
};

export const MailIcon = ({ color, width, height, className, listener }) => {
  const st0 = {
    fill: "none",
    stroke: "#21163A",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
  };
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M28.2,13.8l-9.8,3.6c-0.9,0.3-1.9,0.3-2.7,0l-10-3.6" />
        <path
          style={st0}
          d="M27,7H7c-2.2,0-4,1.8-4,4v12c0,2.2,1.8,4,4,4h20c2.2,0,4-1.8,4-4V11C31,8.8,29.2,7,27,7z"
        />
      </g>
    </svg>
  );
};

export const MemberIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <circle cx="17" cy="9" r="6" />
        <ellipse cx="17" cy="25" rx="10" ry="6" />
      </g>
    </svg>
  );
};

export const PaperIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path
          d="M11.1,3h6.8c1.1,0,2.2,0.5,3.1,1.3l6.7,6.7c0.8,0.8,1.3,1.9,1.3,3.1v11.1c0,3.2-2.6,5.9-5.9,5.9H11.1
		c-3.2,0-5.9-2.6-5.9-5.9V8.9C5.2,5.6,7.8,3,11.1,3z"
        />
        <line x1="22.4" y1="19.5" x2="11.6" y2="19.5" />
        <line x1="18.3" y1="23.9" x2="11.6" y2="23.9" />
        <path d="M17.9,3v8.5c0,1.3,1.1,2.4,2.4,2.4h8.5" />
      </g>
    </svg>
  );
};

export const PhoneIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <path
        d="M28.8,20.3l-4-1.6c-1.2-0.5-2.6-0.3-3.5,0.5l-1,0.9c-0.5,0.4-1,0.6-1.6,0.6c-0.7,0-1.3-0.3-1.8-0.8L14,17.1
      c-0.9-0.9-1-2.4-0.1-3.4l0.9-1c0.8-1,1-2.3,0.5-3.5l-1.6-3.9C13.2,3.9,11.9,3,10.5,3H6.3C4.5,3,3,4.5,3,6.3C3,19.9,14.1,31,27.7,31
      c1.8,0,3.3-1.5,3.3-3.3v-4.2C31,22.1,30.1,20.8,28.8,20.3z"
      />
    </svg>
  );
};

export const RefreshIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M4.9,10C7.3,5.8,11.8,3,17,3c7.7,0,14,6.3,14,14s-6.3,14-14,14S3,24.7,3,17" />
        <line x1="4.9" y1="10" x2="4.9" y2="3.1" />
        <line x1="4.9" y1="10" x2="11.7" y2="10" />
      </g>
    </svg>
  );
};

export const ReservationIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  const st0 = {
    fill: "none",
    stroke: "#21163A",
    strokeWidth: 1.5,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
  };

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <line x1="13.5" y1="7.4" x2="13.5" y2="3" />
        <line x1="26.6" y1="7.4" x2="26.6" y2="3" />
        <line style={st0} x1="22" y1="23.6" x2="22" y2="23.6" />
        <line style={st0} x1="25.9" y1="23.6" x2="25.9" y2="23.6" />
        <line style={st0} x1="22" y1="19.9" x2="22" y2="19.9" />
        <line style={st0} x1="25.9" y1="19.9" x2="25.9" y2="19.9" />
        <line style={st0} x1="18.1" y1="16.2" x2="18.1" y2="16.2" />
        <line style={st0} x1="22" y1="16.2" x2="22" y2="16.2" />
        <line style={st0} x1="25.9" y1="16.2" x2="25.9" y2="16.2" />
        <path
          style={st0}
          d="M9.1,15.6V10c0-1.4,1.2-2.6,2.6-2.6h16.6c1.4,0,2.6,1.2,2.6,2.6v14.8c0,1.4-1.2,2.6-2.6,2.6H17.3"
        />
        <line x1="9.1" y1="12.3" x2="31" y2="12.3" />
        <circle cx="10.8" cy="23.2" r="7.8" />
        <polyline points="10.8,18 10.8,23.2 12.9,25.3" />
      </g>
    </svg>
  );
};

export const UserNameIcon = ({ color, width, height, className, listener }) => {
  const st0 = {
    fill: "none",
    stroke: "#21163A",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: 10,
  };
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path
          d="M31,16c0,1.3-1,2.4-2.3,2.4c-1.4,5.1-6.1,8.8-11.7,8.8c-5.6,0-10.2-3.7-11.7-8.8C4,18.4,3,17.3,3,16
        c0-1.2,0.9-2.2,2-2.3C5.7,7.7,10.8,3,17,3c6.2,0,11.3,4.7,12,10.7C30.2,13.9,31,14.9,31,16z"
        />
        <line x1="13.3" y1="31" x2="13.3" y2="26.6" />
        <line x1="20.7" y1="31" x2="20.7" y2="26.6" />
        <line style={st0} x1="12.4" y1="16" x2="12.4" y2="16" />
        <line style={st0} x1="21.6" y1="16" x2="21.6" y2="16" />
        <path d="M15.7,7.2c-2.4,3.2-6.2,5.3-10.5,5.3" />
        <path d="M28.7,11.8c-1.4,0.6-2.9,0.9-4.6,0.9c-3.8,0-7.1-1.8-9.3-4.5" />
      </g>
    </svg>
  );
};

export const UsersIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M3,31v-1.7c0-4.8,3.9-8.7,8.7-8.7s8.7,3.9,8.7,8.7V31" />
        <circle cx="11.7" cy="13" r="5.3" />
        <path d="M17.5,17c1.4-1.6,3.5-2.7,5.8-2.7c4.2,0,7.7,3.4,7.7,7.7" />
        <circle cx="23.3" cy="7.6" r="4.6" />
      </g>
    </svg>
  );
};

export const UsersArchiveIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M3,31l0-1.4c0-4.6,3.5-8.7,8.1-9c5.1-0.3,9.3,3.7,9.3,8.7V31" />
        <circle cx="11.7" cy="13" r="5.3" />
        <g>
          <g>
            <path d="M20.4,6.7h9.5v5.9c0,1.1-0.9,2-2,2h-5.5c-1.1,0-2-0.9-2-2V6.7z" />
            <path
              d="M22.2,3h6C29.7,3,31,4.3,31,5.8v0c0,0.5-0.4,0.9-0.9,0.9h-9.8c-0.5,0-0.9-0.4-0.9-0.9v0
            C19.3,4.3,20.6,3,22.2,3z"
            />
            <line x1="23.5" y1="9.2" x2="26.9" y2="9.2" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const UsersBlockIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M3,31v-1.7c0-4.8,3.9-8.7,8.7-8.7s8.7,3.9,8.7,8.7V31" />
        <circle cx="11.7" cy="13" r="5.3" />
        <circle cx="24.6" cy="9.4" r="6.4" />
        <line x1="22.1" y1="6.9" x2="27.1" y2="11.9" />
        <line x1="27.1" y1="6.9" x2="22.1" y2="11.9" />
      </g>
    </svg>
  );
};

export const UserMinusIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <path d="M3,31v-1.7c0-4.8,3.9-8.7,8.7-8.7s8.7,3.9,8.7,8.7V31" />
        <circle cx="11.7" cy="13" r="5.3" />
        <circle cx="24.6" cy="9.4" r="6.4" />
        <line x1="21.1" y1="9.4" x2="28.1" y2="9.4" />
      </g>
    </svg>
  );
};

export const EyeIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={listener}
    >
      <g>
        <path
          d="M30.8,16.1c0.2,0.6,0.2,1.2,0,1.8c-1.9,5-7.4,8.7-13.9,8.7c-6.4,0-11.8-3.5-13.8-8.4c-0.3-0.7-0.3-1.5,0-2.3
		C5.2,11,10.6,7.5,17,7.5C23.5,7.5,29,11.1,30.8,16.1z"
        />
        <circle cx="17" cy="17" r="3.8" />
      </g>
    </svg>
  );
};

export const OffresSharedIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <g>
          <g>
            <path d="M12.9,23.8c-0.3,0-0.6,0-0.9,0c-4.8,0-8.6,3.9-8.6,8.6S7.2,41,12,41s8.6-3.9,8.6-8.6c0-0.3,0-0.6,0-0.9" />
            <path
              d="M17.6,32.2l3.8-1c0.9-0.2,1.2-1.4,0.6-2l-6.8-6.8c-0.7-0.7-1.8-0.3-2,0.6l-1,3.8c-0.3,1.4-1,2.6-2,3.6
				L8.4,32c-0.8,0.8-0.8,2,0,2.8l1.1,1.1c0.8,0.8,2,0.8,2.8,0l1.7-1.7C15,33.2,16.2,32.5,17.6,32.2z"
            />
            <line x1="11.6" y1="28.4" x2="14.3" y2="31.1" />
            <line x1="18.5" y1="19.8" x2="18.5" y2="18" />
            <path d="M20.7,27.9c1.2-1.2,1.2-3.1,0-4.3s-3.1-1.2-4.3,0" />
            <line x1="24.5" y1="25.8" x2="26.3" y2="25.8" />
            <line x1="22.4" y1="21.9" x2="23.7" y2="20.7" />
            <line x1="11.8" y1="36.3" x2="15.3" y2="39.8" />
            <line x1="13.5" y1="34.7" x2="17.6" y2="38.9" />
          </g>
        </g>
        <path
          d="M30.8,12.1l5.4,3.2 M36.2,6.6l-5.4,3.2 M40.7,5.4c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4S37,3,38.3,3
		C39.6,3,40.7,4.1,40.7,5.4z M31.2,10.9c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4C30.1,8.5,31.2,9.6,31.2,10.9
		z M40.7,16.5c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4C39.6,14.1,40.7,15.1,40.7,16.5z"
        />
      </g>
    </svg>
  );
};

export const OffresCanceledIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <circle cx="32.3" cy="11.7" r="8.7" />
        <line x1="27.5" y1="11.7" x2="37" y2="11.7" />
        <circle cx="32.3" cy="11.7" r="8.7" />
        <line x1="27.5" y1="11.7" x2="37" y2="11.7" />
        <g>
          <g>
            <path
              d="M12.5,23.8c-0.3,0-0.6,0-0.9,0c-4.8,0-8.6,3.9-8.6,8.6S6.9,41,11.7,41c4.8,0,8.6-3.9,8.6-8.6
            c0-0.3,0-0.6,0-0.9"
            />
            <path
              d="M17.2,32.2l3.8-1c0.9-0.2,1.2-1.4,0.6-2l-6.8-6.8c-0.7-0.7-1.8-0.3-2,0.6l-1,3.8c-0.3,1.4-1,2.6-2,3.6
            l-1.7,1.7c-0.8,0.8-0.8,2,0,2.8L9.2,36c0.8,0.8,2,0.8,2.8,0l1.7-1.7C14.5,33.2,15.8,32.5,17.2,32.2z"
            />
            <line x1="11.2" y1="28.4" x2="13.8" y2="31.1" />
            <line x1="18.2" y1="19.8" x2="18.2" y2="18.1" />
            <path
              d="M20.3,27.9c1.2-1.2,1.2-3.1,0-4.3s-3.1-1.2-4.3,0"
            />
            <line x1="24.2" y1="25.8" x2="25.8" y2="25.8" />
            <line x1="22" y1="21.9" x2="23.3" y2="20.7" />
            <line x1="11.3" y1="36.3" x2="14.8" y2="39.8" />
            <line x1="13" y1="34.8" x2="17.3" y2="38.9" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const TransactionIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <path
        d="M23,5h8.4c3.4,0,5,0,6.3,0.7c1.1,0.6,2,1.5,2.6,2.6c0.7,1.3,0.7,3,0.7,6.3v16.8c0,3.4,0,5-0.7,6.3
	c-0.6,1.1-1.5,2-2.6,2.6c-1.3,0.7-3,0.7-6.3,0.7H14.6c-3.4,0-5,0-6.3-0.7c-1.1-0.6-2-1.5-2.6-2.6C5,36.4,5,34.7,5,31.4V23 M15,25v8
	 M31,21v12 M23,13v20 M3,9l6-6 M9,3l6,6 M9,3v12"
      />
    </svg>
  );
};

export const CommandsReqIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <g>
          <g>
            <path
              d="M10.8,27.9H4c-0.6,0-1-0.4-1-1v-7.8c0-0.6,0.4-1,1-1h6.8c0.6,0,1,0.4,1,1v7.8C11.8,27.4,11.3,27.9,10.8,27.9
            z"
            />
            <path
              d="M10.8,41.1H4c-0.6,0-1-0.4-1-1v-7.8c0-0.6,0.4-1,1-1h6.8c0.6,0,1,0.4,1,1v7.8C11.8,40.6,11.3,41.1,10.8,41.1
            z"
            />
            <line x1="14.5" y1="32.9" x2="26" y2="32.9" />
            <line x1="14.5" y1="36.8" x2="20.9" y2="36.8" />
            <line x1="14.5" y1="19.8" x2="26" y2="19.8" />
            <line x1="14.5" y1="23.6" x2="20.9" y2="23.6" />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M25.6,9.2c-0.1-0.2-0.2-0.3-0.2-0.4s0-0.3,0-0.4s0.1-0.2,0.2-0.4c0.9-1.4,3.5-5,7.6-5c4,0,6.7,3.6,7.6,5
            C40.9,8.1,41,8.3,41,8.4s0,0.3,0,0.4s-0.1,0.2-0.2,0.4c-0.9,1.4-3.5,5-7.6,5C29.2,14.1,26.5,10.6,25.6,9.2z"
            />
            <path d="M33.2,10.9c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4s-2.4,1.1-2.4,2.4S31.9,10.9,33.2,10.9z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const CommandsCanceledIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <circle cx="32.4" cy="11.7" r="8.7" />
        <line x1="27.6" y1="11.7" x2="37.1" y2="11.7" />
        <circle cx="32.4" cy="11.7" r="8.7" />
        <line x1="27.6" y1="11.7" x2="37.1" y2="11.7" />
        <g>
          <g>
            <path
              d="M10.8,27.9H3.9c-0.6,0-1-0.4-1-1v-7.8c0-0.6,0.4-1,1-1h6.8c0.6,0,1,0.4,1,1v7.8
				C11.8,27.4,11.4,27.9,10.8,27.9z"
            />
            <path d="M10.8,41H3.9c-0.6,0-1-0.4-1-1v-7.8c0-0.6,0.4-1,1-1h6.8c0.6,0,1,0.4,1,1V40C11.8,40.5,11.4,41,10.8,41z" />
            <line x1="14.6" y1="32.9" x2="25.9" y2="32.9" />
            <line x1="14.6" y1="36.7" x2="20.9" y2="36.7" />
            <line x1="14.6" y1="19.7" x2="25.9" y2="19.7" />
            <line x1="14.6" y1="23.5" x2="20.9" y2="23.5" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const CommandsPayedByCashIcon = ({
  color,
  width,
  height,
  className,
  listener,
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <g>
          <g>
            <path
              d="M10.8,27.9H4c-0.6,0-1-0.4-1-1v-7.8c0-0.6,0.4-1,1-1h6.8c0.6,0,1,0.4,1,1v7.8C11.8,27.5,11.3,27.9,10.8,27.9
				z"
            />
            <path d="M10.8,41H4c-0.6,0-1-0.4-1-1v-7.8c0-0.6,0.4-1,1-1h6.8c0.6,0,1,0.4,1,1V40C11.8,40.6,11.3,41,10.8,41z" />
            <line x1="14.5" y1="32.9" x2="26" y2="32.9" />
            <line x1="14.5" y1="36.7" x2="20.9" y2="36.7" />
            <line x1="14.5" y1="19.8" x2="26" y2="19.8" />
            <line x1="14.5" y1="23.6" x2="20.9" y2="23.6" />
          </g>
        </g>
        <g>
          <path
            d="M24.7,3.5l1.9-0.3c1.9-0.3,3.7-0.3,5.6,0h0.2c1.9,0.3,3.7,0.3,5.6,0L39.5,3C40.3,2.9,41,3.5,41,4.3v7.9
			c0,0.7-0.5,1.2-1.1,1.3L38,13.8c-1.9,0.3-3.7,0.3-5.6,0h-0.2c-1.9-0.3-3.7-0.3-5.6,0L25.1,14c-0.8,0.1-1.5-0.5-1.5-1.3V4.8
			C23.6,4.2,24.1,3.7,24.7,3.5z"
          />
          <ellipse cx="32.3" cy="8.6" rx="2.3" ry="2.3" />
          <ellipse cx="27" cy="6.3" rx="0.3" ry="0.3" />
          <ellipse cx="27" cy="10.8" rx="0.3" ry="0.3" />
          <ellipse cx="37.6" cy="6.3" rx="0.3" ry="0.3" />
          <ellipse cx="37.6" cy="10.8" rx="0.3" ry="0.3" />
        </g>
      </g>
    </svg>
  );
};

export const ClimIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <circle cx="22" cy="22" r="19" />
        <line x1="22" y1="7.6" x2="22" y2="22" />
        <line x1="29.1" y1="14.9" x2="22" y2="22" />
        <line x1="22" y1="22" x2="14.9" y2="14.9" />
        <polyline points="29.1,11.1 29.1,14.9 32.9,14.9 	" />
        <polyline points="14.9,11.1 14.9,14.9 11.1,14.9 	" />
        <polyline points="27.3,8.3 22,13.7 16.7,8.3 	" />
        <line x1="22" y1="36.4" x2="22" y2="22" />
        <line x1="29.1" y1="29.1" x2="22" y2="22" />
        <line x1="22" y1="22" x2="14.9" y2="29.1" />
        <polyline points="29.1,32.9 29.1,29.1 32.9,29.1 	" />
        <polyline points="14.9,32.9 14.9,29.1 11.1,29.1 	" />
        <polyline points="27.3,35.7 22,30.3 16.7,35.7 	" />
        <line x1="36.4" y1="22" x2="22" y2="22" />
        <polyline points="35.7,27.3 30.3,22 35.7,16.7 	" />
        <line x1="7.6" y1="22" x2="22" y2="22" />
        <polyline points="8.3,27.3 13.7,22 8.3,16.7 	" />
      </g>
    </svg>
  );
};

export const NoSmokeIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <circle cx="22" cy="22" r="19" />
        <path d="M22.4,15.7c-1.4,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6" />
        <path d="M30.3,15.7c1.4,0,2.6,1.2,2.6,2.6" />
        <rect x="11.1" y="22" width="21.7" height="4.2" />
        <line x1="22.4" y1="10.5" x2="27.7" y2="10.5" />
        <line x1="30.3" y1="15.7" x2="22.4" y2="15.7" />
        <line x1="17" y1="22" x2="17" y2="26.2" />
        <line x1="29.4" y1="22" x2="29.4" y2="26.2" />
        <line x1="8.6" y1="8.5" x2="35.5" y2="35.4" />
      </g>
    </svg>
  );
};

export const PetIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    //   onClick={() => listener()}
    >
      <g>
        <ellipse cx="6.8" cy="18.3" rx="3.8" ry="3.8" />
        <ellipse
          transform="matrix(0.9236 -0.3834 0.3834 0.9236 -2.7759 6.6522)"
          cx="15.3"
          cy="10.3"
          rx="4.1"
          ry="6.1"
        />
        <ellipse
          transform="matrix(0.3834 -0.9236 0.9236 0.3834 8.2479 32.7636)"
          cx="28.7"
          cy="10.2"
          rx="6.1"
          ry="4.1"
        />
        <ellipse cx="37.2" cy="18.3" rx="3.8" ry="3.8" />
        <path d="M22,20.6c-4.9,0-11.3,3.3-12.4,11.5c-0.5,4.2,2.6,7.5,6.1,7.5s3.8-2.2,6.3-2.2" />
        <path d="M22,20.6c4.9,0,11.3,3.3,12.4,11.5c0.5,4.2-2.6,7.5-6.1,7.5s-3.8-2.2-6.3-2.2" />
      </g>
    </svg>
  );
};

export const OptionIcon = ({ color, width, height, className, listener }) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      fill={color}
      width={width}
      height={height}
      className={className}
      viewBox="0 0 26 26"
      style={{
        enableBackground: "new 0 0 26 26",
      }}
      xmlSpace="preserve"
    >
      <style>
        {
          ".st0{fill:none;stroke:#595861;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="st0"
        d="M6 2h14c2.2 0 4 1.8 4 4v14c0 2.2-1.8 4-4 4H6c-2.2 0-4-1.8-4-4V6c0-2.2 1.8-4 4-4z"
      />
      <path
        className="st0"
        d="M6 9.9h9.3m0 0c0 1.3 1 2.3 2.3 2.3s2.3-1 2.3-2.3-1-2.3-2.3-2.3-2.3 1-2.3 2.3zm-4.6 6.2H20m-9.3 0c0 1.3-1 2.3-2.3 2.3S6 17.4 6 16.1s1-2.3 2.3-2.3 2.4 1 2.4 2.3z"
      />
    </svg>
  )

}

export const NoActiveEye = ({ color, width, height, className, listener }) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 34 34"
      style={{
        enableBackground: "new 0 0 34 34",
      }}
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={() => listener()}

      xmlSpace="preserve"
    >
      <style>
        {
          ".st0{fill:none;stroke:#86898d;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
        }
      </style>
      <path
        className="st0"
        d="M25.2 25.2c2-1.2 3.7-2.9 5-5 .6-1 .9-2.1.9-3.2v0c0-1.1-.3-2.3-.9-3.2-2.8-4.5-7.7-7.4-13.2-7.4-1.9 0-3.6.3-5.3.9M8.8 8.8c-2 1.2-3.7 2.9-4.9 4.9-.6 1-.9 2.1-.9 3.3v0c0 1.1.3 2.3.9 3.2 2.7 4.4 7.6 7.3 13.1 7.3 1.8 0 3.6-.3 5.2-.9M5 5l24 24"
      />
      <path
        className="st0"
        d="M13.2 13.2c1-1 2.3-1.6 3.8-1.6 3 0 5.4 2.4 5.4 5.4 0 .3 0 .6-.1.9M20.8 20.8c-1 1-2.3 1.6-3.8 1.6-3 0-5.4-2.4-5.4-5.4 0-.3 0-.6.1-.9"
      />
    </svg>
  )
}
export const ActiveEye = ({ color, width, height, className, listener }) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 34 34"
      style={{
        enableBackground: "new 0 0 34 34",
      }}
      xmlSpace="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={() => listener()}
    >
      <style>
        {".st0{fill:none;stroke:#009d88;stroke-width:1.5;stroke-miterlimit:10}"}
      </style>
      <path
        className="st0"
        d="M3 17c0 1.1.3 2.3.9 3.2 2.7 4.4 7.6 7.3 13.1 7.3s10.4-2.9 13.1-7.3c.6-1 .9-2.1.9-3.2s-.3-2.3-.9-3.2C27.4 9.4 22.5 6.5 17 6.5S6.6 9.4 3.9 13.8c-.6.9-.9 2.1-.9 3.2z"
      />
      <circle className="st0" cx={17} cy={17} r={5.4} />
    </svg>
  )
}
