import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const URL_FILES = process.env.REACT_APP_URL_FILES;
const USER = JSON.parse(localStorage.getItem("userInfo")) || null;
const TOKEN = USER?.token;

export const publicRequest = axios.create({
  baseURL: API_URL,
});

export const userRequest = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: "Bearer" + TOKEN,
    "Content-Type": "application/json",
  },
  // withCredentials:true
});

export const privateRequest = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials:true
});

export const userRequestFiles = axios.create({
  baseURL: URL_FILES,
  headers: {
    Authorization: "Bearer" + TOKEN,
    "content-type": "multipart/form-data",
  },
});
